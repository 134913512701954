<template>
  <div
    class="base-input focus-within:ring-2 w-full relative"
    :class="[
      borderless ? 'is-borderless' : 'rounded-md',
      { 'is-dark': dark },
      { 'has-error': hasError },
      wrapperClass
    ]"
  >
    <input
      :id="id"
      v-bind="$attrs"
      v-model="computedModel"
      class="block w-full border-0 p-0 placeholder:text-brand-alternate-20 focus:ring-0 autofill:bg-transparent peer"
      :class="[
        {'text-base md:text-base': size === 'xl'},
        {'text-xs md:leading-normal md:text-sm': size === 'base'},
        { 'px-6 pb-3.5 pt-6': size === 'xl' },
        { 'px-6 pb-2 pt-3.5': size === 'base' },
        inputClass,
      ]"
      placeholder=""
      :aria-label="label"
    >
    <label
      :for="id"
      class="select-none pointer-events-none block text-brand-black/50 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto duration-300 transform z-10 origin-[0] start-6 absolute"
      :class="[
        {'peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-placeholder-shown:translate-y-0 -translate-y-4 top-[1.25rem] scale-90 peer-focus:scale-90': size === 'xl'},
        {'peer-placeholder-shown:scale-95 peer-focus:-translate-y-3 peer-placeholder-shown:translate-y-0 -translate-y-3 top-2 scale-75 peer-focus:scale-75': size === 'base'}
      ]"
    >
      {{ label }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { type InputHTMLAttributes } from 'vue';
import type { InputSizes } from '~/types';

defineOptions({
  inheritAttrs: false,
});

interface Props extends /* @vue-ignore */ Partial<InputHTMLAttributes> {
  id: string
  hasError?: boolean
  dark?: boolean
  label?: string
  size?: InputSizes
  modelValue?: string | number
  borderless?: boolean
  wrapperClass?: string
  inputClass?: string
  type?: InputHTMLAttributes['type']
}

withDefaults(defineProps<Props>(), {
  dark: false,
  type: 'text',
  hasError: false,
  label: undefined,
  modelValue: undefined,
  size: 'base',
  borderless: false,
  wrapperClass: undefined,
  inputClass: undefined,
});

const computedModel = defineModel<Props['modelValue']>();
</script>

<style scoped>
.base-input {
  input {
    @apply rounded-md;
  }
  &:not(.is-dark) {
    input {
      @apply text-brand-black bg-brand-white;
    }
    &.has-error {
      label {
        @apply text-brand-error;
      }
    }
  }
  &:not(.is-borderless) {
    @apply border-brand-secondary/20 border shadow-lg;
  }
  &.is-dark {
    @apply bg-white/5 focus-within:ring-brand-white border-white/40 border;
    &.has-error {
      label {
        @apply text-brand-warning;
      }
    }
    label {
      @apply text-gray-200;
    }
    input {
      @apply text-brand-white bg-brand-primary/10;
    }
  }
}
</style>
